
.navigation-wide-screen-wrapper {
  transition-property: all;
  transition-duration: 200ms;

  .collapse-button {
    margin: 10px;
  }

  .content-wrapper {

  }
}

