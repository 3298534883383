.student-page{

    .headline{
        text-align: center;
    }

    .register-wrapper{
        
    }
}

